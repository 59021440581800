<template>
    <div style="padding-top: 8em;">
        <header-service :title="'Article'" :subtitle="'Portal Informasi Bisnis Legalpedia'"></header-service>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-3 mb-3">
            <v-container>
                <v-row>
                    <v-col class="col-12" md="8">
                        <v-row>
                            <div class="col-sm-12 mt-0 mb-0" v-for="(article, i) in articles" :key="i">
                                <div class="card border-0">
                                    <div class="card-body rounded">
                                        <div class="row align-items-center">
                                        <div class="col-sm-3">
                                            <!-- <img :src="article.image" class="img-fluid rounded" style="min-height:180px"> -->
                                            <v-img
                                                :src="article.image"
                                                :lazy-src="article.image"
                                                aspect-ratio="1"
                                                class="grey lighten-2 rounded-l"
                                                min-height="180px"
                                            
                                            >
                                                <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="h3">
                                                {{ article.title }}
                                            </p>
                                            <div class="mh-meta mh-loop-meta">
                                                <span class="mh-meta-date updated mr-3"><v-icon small>mdi-clock-outline</v-icon>{{ article.created_at }}</span>
                                                <span class="mh-meta-author author vcard">
                                                    <v-icon small class="mr-2">mdi-account</v-icon>
                                                    <a class="fn" href="">{{article.author}}</a>
                                                </span>
                                            </div>
                                            <div v-html="article.description.substr(0, 220)"></div>...
                                            <div class="text-left mt-3">
                                                <v-btn color="primary" text :to="'/article/'+article.slug+'/detail'">
                                                    Read More
                                                </v-btn>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <v-divider class="mt-0 mb-0"></v-divider>
                            </div>
                            <v-col cols="12">
                                <v-pagination
                                    v-model="pagination.current"
                                    :length="pagination.total"
                                    @input="onPageChange"
                                    circle
                                ></v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="col-12" md="4">
                        <v-text-field
                            solo
                            class="border-12"
                            label="Search ..."
                            append-icon="mdi-magnify"
                        ></v-text-field>
                        <v-card
                            elevation="24"
                            max-width="444"
                            class="mx-auto"
                        >
                            <v-carousel
                            :continuous="false"
                            :cycle="cycle"
                            :show-arrows="false"
                            hide-delimiter-background
                            delimiter-icon="mdi-minus"
                            height="300"
                            >
                            <v-carousel-item
                                v-for="(slide, i) in articles"
                                :key="i"
                            >
                                <v-sheet
                                height="100%"
                                tile
                                >
                                <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center"
                                >
                                <v-img
                                    :src="slide.image"
                                    :lazy-src="slide.image"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                    min-height="180px"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                </v-row>
                                </v-sheet>
                            </v-carousel-item>
                            </v-carousel>
                            <v-list two-line>
                            <v-list-item>
                                <v-list-item-avatar>
                                <v-img src="@/assets/default-user.png"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>Admin</v-list-item-title>
                                <v-list-item-subtitle>Author</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                <v-switch
                                    v-model="cycle"
                                    label="Cycle Slides"
                                    inset
                                ></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {article_api} from "@/backend-api/article_api"
import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
components: {
    ContactUs, HeaderService
},
data: () => ({
    data : '',
    breadcumbs: [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Article',
            disabled: true,
        }
    ],
    articles:[],
    page: 1,
    cycle: true,
    pagination: {
        current: 1,
        total: 0
    }
}),
methods:{
    async getArticle(){
      var respData = await article_api.front_paginate(`?status=1&page=${this.pagination.current}`, null, false, false, false)
      if (respData.status === 200) {
        this.articles = respData.data.data ? respData.data.data : []
        this.pagination.current = respData.data.meta.current_page;
        this.pagination.total = respData.data.meta.last_page;
      } 
    },
    onPageChange() {
        this.getArticle();
    }
},
async mounted() { 
    await this.getArticle(); 
},
watch: {
}
}
</script>

<style scoped>

</style>